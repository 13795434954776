import { graphql } from 'gatsby';
import * as React from 'react';
import Carousel from '../components/carousel.component';
import Layout from '../components/layout.component';

function WorshipPage(props) {
  return (
    <Layout>
      <Carousel indexPage={props.data.dataJson.indexPage} coverText={`Services`}></Carousel>
    </Layout>
  );
}

export const query = graphql`
  query WorshipQuery {
    dataJson {
      indexPage {
        images
      }
      churchName
    }
  }
`;

export default WorshipPage;
